<!--
 * @FileDescription:Dashboard
 * @Author: 蔡林泽
 * @Date: 2021/2/24
 * @LastModifiedTime: 2021/2/24
 -->
<template>
  <div class="main">
    <div>
      <h1>Dashboard</h1>
      <div class="box">
        <h1>Your referral links :</h1>
        <span>Please note that only these links are valid as your referral links for you to promote, track your referrals and calculate your commission.</span>
        <div class="link">
          <div v-for="item in tableData" :key="item.id" class="f">
            <p><i class="el-icon-document-copy" style="font-size:18px;" @click="copy(item.url )" />
              <span @click="Jump(item.url)"> To {{ item.name+' - '+item.url }}</span>
            </p>
            <el-tooltip class="item" effect="dark" content="Promotion Data" placement="top">
              <i class="iconfont icon-shuju" style="font-size:20px;color:#7B60C4;" @click="handleEdit(item)" />
            </el-tooltip>
          </div>

        </div>
      </div>
      <!-- 折线图 -->
      <div class="content">
        <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="Start date" end-placeholder="End date" style="margin:30px 20px 23px 0;" :picker-options="pickerOptions" @change="pickerChange" />
        <el-radio-group v-model="listForm.type" size="medium" @change="groupChange">
          <el-radio-button label="hour">Hour</el-radio-button>
          <el-radio-button label="day">Day</el-radio-button>
          <el-radio-button label="week">Week</el-radio-button>
          <el-radio-button label="month">Month</el-radio-button>
        </el-radio-group>
        <div class="echart-box">
          <div class="echart-title f-b">
            <div class="fd-c f-c" style="height:118px;">
              <div>
                <p style="color: #747A87;font-weight: 500;">Total Order Value</p>
              </div>
              <div class="f-b">
                <span style="color: #000000;;font-weight: 500;font-size:26px;margin-top: 10px;">US$ {{ Liststats.order_amount }}</span>
                <i class="iconfont icon-shuju1" style="font-size:29px;color:#D14A4A; margin-top: 15px;" />
              </div>
            </div>
            <div class="fd-c f-c" style="height:118px;">
              <div>
                <p style="color: #747A87;font-weight: 500;">Total Orders</p>
              </div>
              <div class="f-b">
                <span style="color: #000000;;font-weight: 500;font-size:26px;margin-top: 10px;">{{ Liststats.count }}</span>
                <i class="iconfont icon-shuju1" style="font-size:29px;color:#27AE60; margin-top: 15px;" />
              </div>
            </div>
            <div class="fd-c f-c" style="height:118px;">
              <div>
                <p style="color: #747A87;font-weight: 500;">Total Revenue</p>
              </div>
              <div class="f-b">
                <span style="color: #000000;;font-weight: 500;font-size:26px;margin-top: 10px;">US$ {{ Liststats.commision }}</span>
                <i class="iconfont icon-shuju1" style="font-size:29px;color:#2F80ED; margin-top: 15px;" />
              </div>
            </div>
          </div>
          <div class="echart f-b">
            <div id="echart-Orders" class="echart" :style="{width: '33%', height: '380px',marginTop:'20px'}" />
            <div id="echart-Sales" class="echart" :style="{width: '33%', height: '380px',marginTop:'20px'}" />
            <div id="echart-Revenue" class="echart" :style="{width: '33%', height: '380px',marginTop:'20px'}" />
          </div>
        </div>
      </div>
      <!-- 详情弹窗 -->
      <Details ref="details" />
    </div>

  </div>
</template>

<script>
import API from '@/api/index'
import list from '@/mixin/list'
// import Details from './components/Details.vue'
import { copy } from '@/util/common.js'
import Details from '../mylink/components/Details.vue'

// echarts插件按需引入
import * as echarts from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { dateFormat } from '@/util/date.js'
echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition])

const options = [
  { value: '0', label: 'Ongoing' },
  { value: '1', label: 'End' }
]

export default {
  components: { Details },
  mixins: [list],
  data() {
    return {
      options,
      tableData: [],
      loading: false,
      pickerOptions: {
        onPick: ({
          maxDate,
          minDate
        }) => {
          this.maxDate = maxDate
          this.minDate = minDate
        },
        disabledDate: (date) => {
          return this.disabledDatecondition(date)
        }
      },
      time: '',
      Days: 30,
      listForm: {
        type: 'day'
      },
      maxDate: '',
      minDate: '',
      Liststats: '',
      // 统计数据
      statistics: ''
    }
  },
  created() {
    // 当前日期前一个月的时间戳
    let data = Date.now() - 30 * 24 * 60 * 60 * 1000
    this.time = [dateFormat(data, 'yyyy-MM-dd'), dateFormat(Date.now(), 'yyyy-MM-dd')]
    this.getList()
    this.pickerChange()
  },
  mounted() {

  },
  methods: {
    copy,
    handleEdit(row) {
      this.$refs.details.showDialog(row)
    },
    getList() {
      let parmas = Object.assign({}, this.listForm)
      this.loading = true
      API.getPopularize(parmas).then(res => {
        const list = res.data.list || []
        list.forEach(item => {
          item.state = +item.state === 0 ? 'Ongoing' : 'End'
          // 前端暂时写死推广域名
          if (process.env.NODE_ENV === 'production') {
            item.url = `https://affiliate.kakaclo.com/redicect/${item.code}`
          } else {
            item.url = `http://wm.hbo-erp.com/redicect/${item.code}`
          }
        })
        this.tableData = list
        this.total = res.data.pages.total
      }).finally(() => {
        this.loading = false
      })
    },
    Jump(data) {
      window.open(data)
    },
    groupChange() {
      // 业务逻辑 ：时间颗粒度可选择 小时、天(可筛选一个月区间)、周(可筛选三个月区间)、月(可筛选12个月区间)
      this.listForm.type === 'day' ? this.Days = +30 : this.listForm.type === 'week' ? this.Days = +90 : this.listForm.type === 'month' ? this.Days = +365 : this.Days = 0
      let data = Date.now() - this.Days * 24 * 60 * 60 * 1000
      // 筛选时间颗粒自动获取 当天的最大范围时间
      this.time = [dateFormat(data, 'yyyy-MM-dd'), dateFormat(Date.now(), 'yyyy-MM-dd')]
      this.pickerChange()
    },
    disabledDatecondition(date) {
      let maxDate = this.maxDate
      let minDate = this.minDate
      let endTime = ''
      if (minDate) {
        // 此刻时间戳 - 筛选开始时间的 + 一个月时间戳
        let val = Date.now() - (new Date(this.minDate).valueOf() + this.Days * 24 * 60 * 60 * 1000)
        // 当天的时间戳                          筛选开始时间的时间戳
        val < 0 ? endTime = Date.now() - 60 * 60 * 1000 : endTime = (new Date(this.minDate).valueOf() + this.Days * 24 * 60 * 60 * 1000)
        // 当开始时间 和 结束时间都有值 时,不禁用状态，可再次筛选所有时间段
        if (minDate && maxDate) return date.valueOf() > Date.now() - 60 * 60 * 1000
        return date && (date.valueOf() > endTime || date.valueOf() < minDate.valueOf() - this.Days * 24 * 60 * 60 * 1000)
      } else {
        return date && (date.valueOf() > Date.now() - 60 * 60 * 1000)
      }
    },
    pickerChange() {
      this.getDailyStats()
      this.getListstats()
    },
    getDailyStats() {
      let parmas = {
        start: this.time[0],
        end: this.time[1]
      }
      API.getDailyStats(parmas).then(res => {
        this.statistics = res.data.list
        this.initChart('echart-Orders', this.statistics)
        this.initChart('echart-Sales', this.statistics)
        this.initChart('echart-Revenue', this.statistics)
      })
    },
    initChart(type, data) {
      var chartDom = document.getElementById(type)
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: type === 'echart-Orders' ? 'Total Orders' : type === 'echart-Sales' ? 'Total Sales' : 'Total Revenue'
        },
        xAxis: {
          type: 'category',
          data: data.length < 1 ? '' : data.map(item => item.date)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: data.length < 1 ? '' : type === 'echart-Orders' ? data.map(item => item.order_amount) : type === 'echart-Sales' ? data.map(item => item.count) : data.map(item => item.commision),
            type: 'line',
            itemStyle: {
              normal: {
                color: type === 'echart-Orders' ? '#219653' : type === 'echart-Sales' ? '#f6841a' : '#7b60c4',
                lineStyle: {
                  color: type === 'echart-Orders' ? '#27ae60' : type === 'echart-Sales' ? '#f6841a' : '#7b60c4'
                }
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
    },
    getListstats() {
      let parmas = {
        start: this.time[0],
        end: this.time[1]
      }
      API.getListstats(parmas).then(res => {
        this.Liststats = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  > div {
    padding: 0 20px;
    height: calc(100vh - 90px);
    overflow-y: auto;
  }
  .box {
    width: 100%;
    background: #ffffff;
    height: 286px;
    overflow-y: auto;
    border-radius: 6px;
    padding: 22px 30px;
    > h1 {
      font-weight: bolder;
      font-size: 18px;
      line-height: 27px;
    }
    > span {
      font-size: 13px;
      line-height: 20px;
      color: #99c41c;
    }
    .link {
      .f {
        margin: 20px;
        cursor: pointer;
      }
    }
  }
  .content {
    .echart-box {
      width: 100%;
      .echart-title {
        > div {
          width: 33%;
          background: #ffffff;
          box-shadow: 0px 4px 14px 6px rgba(205, 212, 221, 0.1);
          border-radius: 6px;
          padding: 26px 30px;
        }
      }
    }
    .echart {
      > div {
        background: #ffffff;
        padding-top: 15px;
        padding-left: 10px;
        border-radius: 6px;
      }
    }
  }
}
.link p:hover .el-icon-document-copy {
  color: #99c41c;
}
.width {
  width: 1400px;
  margin: 0 auto;
}
</style>
