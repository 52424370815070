import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Layout from '@/components/layout/Layout.vue'
import login from '@/views/user/Login.vue'
import Register from '@/views/user/Register.vue'
import Forgotpassword from '@/views/user/Forgotpassword.vue'
import MyLink from '@/views/mylink/Index.vue'
import Promote from '@/views/website/Index.vue'
import Dashboard from '@/views/dashboard/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard
      },
      {
        name: 'MyLink',
        path: '/mylink',
        component: MyLink
      },
      {
        name: 'Customer',
        path: '/customer',
        component: (resolve) => require(['@/views/customer/Index.vue'], resolve)
      },
      {
        name: 'Orders',
        path: '/orders',
        component: (resolve) => require(['@/views/orders/Index.vue'], resolve)
      },
      {
        name: 'Balance',
        path: '/balance',
        component: (resolve) => require(['@/views/balance/Index.vue'], resolve)
      },
      {
        path: '/test',
        component: (resolve) => require(['@/views/test.vue'], resolve)
      }
    ]
  },
  {
    name: '登录',
    path: '/login',
    component: login
  },
  {
    name: '注册',
    path: '/register',
    component: Register
  },
  {
    name: '忘记密码',
    path: '/forgotpassword',
    component: Forgotpassword
  },
  {
    name: '网盟官网',
    path: '/promote',
    component: Promote
  },
  {
    name: 'FAQ',
    path: '/faq',
    component: (resolve) => require(['@/views/faq/Index.vue'], resolve)
  },
  {
    name: 'PrivacyPolicy',
    path: '/privacypolicy',
    component: (resolve) =>
      require(['@/views/website/privacyPolicy.vue'], resolve)
  },
  {
    name: 'ProgramTerm',
    path: '/programTerm',
    component: (resolve) =>
      require(['@/views/website/programTerm.vue'], resolve)
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
// 页面跳转不校验token的路由
const NOT_CHECK_TOKEN_PATH = [
  '/login',
  '/forgotpassword',
  '/register',
  '/test',
  '/promote',
  '/faq',
  '/privacypolicy',
  '/programTerm'
]

router.beforeEach((to, from, next) => {
  // 不校验 token
  if (NOT_CHECK_TOKEN_PATH.includes(to.path)) {
    return next()
  }
  // 校验 token
  if (!store.state.common.userInfo.token) {
    return next('/promote')
  }
  next()
})

export default router
