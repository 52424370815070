// 1. 导入Vue
import Vue from 'vue'
import _ from 'lodash'

// 根据 precision（精度） 向下舍入 number。（注： precision（精度）可以理解为保留几位小数。）
Vue.filter('floor', function(number, precision = 2) {
  return _.floor(number, precision)
})

// 根据 precision（精度） 向上舍入 number。（注： precision（精度）可以理解为保留几位小数。）
Vue.filter('ceil', function(number, precision = 2) {
  return _.ceil(number, precision)
})

// 根据 precision（精度） 四舍五入 number。
Vue.filter('round', function(number, precision = 2) {
  return _.round(number, precision)
})

// 数字转千分位
// Vue.filter('thousandth', function(value) {
//   try {
//     const array = String(value).split('.')
//     return Number(array[0]).toLocaleString() + '.' + (array[1] ? (array[1].length === 1 ? array[1] + '0' : array[1]) : '00')
//   } catch (error) {
//     return value
//   }
// })
