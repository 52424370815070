/**
 * Vue Mixin 列表页的分页相关功能封装
 * 注意：获取列表接口统一定义为 getList
 * author：苏子龙
 */
export default {
  data() {
    return {
      list: [], // 当前页面列表数据
      listForm: { // 列表筛选/分页
        page: 1,
        per_page: 20
      },
      total: 0 // 列表数据总条数
    }
  },
  methods: {
    /**
     * 分页回调
     */
    pageChange(page, per_page) {
      this.listForm.page = page
      this.listForm.per_page = per_page
      this.getList()
    },
    /**
     * 当筛选条件变化时，重置到第一页，并调用接口
     */
    resetPage() {
      for (let key in this.listForm) {
        if (this.listForm[key] === '') {
          delete this.listForm[key]
        }
      }
      this.listForm.page = 1
      this.getList()
    },
    /**
     * 重置表单
     * @param {String} form 表单ref
     */
    resetForm(form) {
      this.$refs[form].resetFields()
    }
  }
}
