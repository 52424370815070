/**
 * api 出口
 */

const API = {
  test: params => {
    console.log(params)
    return Promise.resolve()
  }
}

require.context('@/api', true, /\.js$/).keys().forEach(fileName => {
  const apiObject = require(`${fileName}`).default
  for (let func in apiObject) {
    if (API[func]) console.error(`${fileName}文件中${func}方法重复！！！`)
  }
  Object.assign(API, apiObject)
})

export default API
