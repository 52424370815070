<!--
 * @FileDescription:
 * @Author: 蔡林泽
 * @Date: 2021/12/27
 * @LastModifiedTime: 2021/12/27
 -->
<template>
  <div class="main">
    <h1>My Link</h1>
    <div class="list-filter bg-white f">
      <!-- 搜索 -->
      <el-input v-model="listForm.keyword" clearable placeholder="search..." class="input-with-select" style="width:270px;" maxlength="100" @change="resetPage">
        <el-button slot="append" icon="el-icon-search" />
      </el-input>
      <el-select v-model="listForm.status" clearable placeholder="Please choose..." style="width:200px;" @change="resetPage">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </div>
    <TableContainer :current="listForm.page" :total="total" :height="($store.state.common.clientHeight-200)+'px'" @change="pageChange">
      <el-table v-loading="loading" :data="tableData" :height="($store.state.common.clientHeight-257)+'px'">
        <el-table-column prop="name" label="Page" min-width="200" />
        <el-table-column prop="state" label="Page State" min-width="200" />
        <el-table-column label="Referral Link" min-width="200">
          <template slot-scope="scope">
            <copy :text="scope.row.url" class="pointer" style="line-height: 24px;" width="170px" @click="Jump(scope.row)" />
            <!-- <span class="pointer" style="text-decoration:underline" @click="Jump(scope.row.url)">{{ scope.row.url }}</span> -->
          </template>
        </el-table-column>
        <el-table-column prop="pv" label="PV" min-width="200" />
        <el-table-column prop="uv" label="UV" min-width="200" />
        <el-table-column label="Action" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click.passive="handleEdit(scope.row)">Details</el-button>
          </template>
        </el-table-column>
      </el-table>
    </TableContainer>

    <!-- 详情弹窗 -->
    <Details ref="details" />
  </div>
</template>

<script>
import API from '@/api/index'
import list from '@/mixin/list'
import Details from './components/Details.vue'

const options = [
  { value: '0', label: 'Ongoing' },
  { value: '1', label: 'End' }
]

export default {
  components: { Details },
  mixins: [list],
  data() {
    return {
      options,
      tableData: [],
      loading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      let parmas = Object.assign({}, this.listForm)
      this.loading = true
      API.getPopularize(parmas).then(res => {
        const list = res.data.list || []
        list.forEach(item => {
          item.state = +item.state === 0 ? 'Ongoing' : 'End'
          // 前端暂时写死推广域名
          if (process.env.NODE_ENV === 'production') {
            item.url = `https://affiliate.kakaclo.com/redicect/${item.code}`
          } else {
            item.url = `http://wm.hbo-erp.com/redicect/${item.code}`
          }
        })
        this.tableData = list
        this.total = res.data.pages.total
      }).finally(() => {
        this.loading = false
      })
    },
    Jump(row) {
      if (row.state === 'End') return this.$message.warning('The current share link is invalid')
      window.open(row.url)
    },
    handleEdit(row) {
      this.$refs.details.showDialog(row)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
