<!--
 * @FileDescription: 复制文本
 * @Author: 苏子龙
 * @Date: 2021/6/19
 -->
<!--
<copy text="666666666" @click="clickFun" />
<copy style="color: red" text="666666666" @click="clickFun" />
-->

<template>
  <span class="copy">
    <span class="ellipsis" style="display: inline-block" :style="{ maxWidth: width }" :title="text" @click="$emit('click')" v-text="text" />
    <i class="el-icon-document-copy pointer" @click="copy(text)" />
  </span>
</template>

<script>
import { copy } from '@/util/common.js'
export default {
  name: 'Copy',
  props: {
    text: {
      type: String,
      require: true,
      default: ''
    },
    width: {
      type: String,
      default: '200px'
    }
  },
  methods: {
    copy
  }
}
</script>

<style lang="scss" scoped>
.el-icon-document-copy{
  margin-left: 2px;
  visibility: hidden;
}
.copy:hover > .el-icon-document-copy{
  visibility: visible;
}
.copy {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
</style>
