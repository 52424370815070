import { Message } from 'element-ui'
const message = Message
/*
*隐藏邮箱地址 //前两位-邮箱后缀，中间*号替代
 * @param {string} value
 * @returns {string}
*/export function regEmail(email) {
  if (String(email).indexOf('@') > 0) {
    var str = email.split('@')
    var _s = ''
    if (str[0].length > 2) {
      for (var i = 0; i < str[0].length - 2; i++) {
        _s += '*'
      }
    }
    var new_email = str[0].substr(0, 2) + _s + '@' + str[1]
  }
  return new_email
}
/**
 * 复制文本（String中加入 /n 可换行）
 * @param {String} text
*/
export function copy(text) {
  if (!text) return
  try {
    const textarea = document.createElement('textarea')
    textarea.value = text
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
    message.success('Copy success')
  } catch (error) {
    message.error('The current browser does not support copying')
  }
}
/**
 * 获取今天日期，yyyy-MM-dd格式
 * @param {number} 返回前 data 天的日期，不传默认拿今天
 * @return {string}
*/
export function getToday(data) {
  if (!data)data = 0
  let time = new Date(Date.now() - data * 24 * 60 * 60 * 1000).toLocaleDateString()
  time = time.split('/')[0] + '-' + (time.split('/')[1] < 10 ? '0' + time.split('/')[1] : time.split('/')[1]) + '-' + (time.split('/')[2] < 10 ? '0' + time.split('/')[2] : time.split('/')[2])
  return time
}
/**
 * 将二进制转成指定文件下载
 * @params {Blob} data 二进制数据
 * @params {String} fileName 文件名（包含后缀）
 */
export function downloadBlobFile(data, fileName) {
  const blob = new Blob([data])

  // 兼容IE浏览器
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName)
    return
  }

  const a = document.createElement('a')
  a.download = fileName
  a.href = URL.createObjectURL(blob)
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}

