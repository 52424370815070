import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import common from '@/store/modules/common'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common
  },
  mutations: {

  },
  actions: {
  },

  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(state) {
      return {
        common: {
          userInfo: state.common.userInfo
        }
      }
    }
  })]
})
