/**
 * 全局组件配置
 */
import Vue from 'vue'

const components = require.context('@/components/base', false, /\.vue$/)
components.keys().forEach(fileName => {
  const component = components(fileName)
  Vue.component(`${component.default.name}`, component.default)
})
