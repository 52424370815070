/**
 * 网盟dashboard页面接口
 */
import axios from '@/util/request'

const baseURL = process.env.VUE_APP_API_URL

export default {
  getListstats: params => axios({ baseURL, url: '/api/v1/order/stats', method: 'get', params }), // 订单统计数据 v1.2
  getDailyStats: params => axios({ baseURL, url: '/api/v1/order/daily_stats', method: 'get', params }) // 订单按日统计 v1.2
}

