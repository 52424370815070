/**
 * 网盟推广页面接口
 */
import axios from '@/util/request'

const baseURL = process.env.VUE_APP_API_URL

export default {
  getPopularize: params => axios({ baseURL, url: '/api/v1/user/popularize', method: 'get', params }), // 推广页面
  getPopularize_detail: params => axios({ baseURL, url: '/api/v1/user/popularize_detail', method: 'get', params }) // 推广页面明细
}

