export function dateFormat(date = new Date(), format = 'yyyy-MM-dd HH:mm:ss') {
  try {
    date = new Date(date)
  } catch (error) {
    return ''
  }
  const yyyy = date.getFullYear()
  const MM = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const HH = date.getHours()
  const mm = date.getMinutes()
  const ss = date.getSeconds()
  let result = ''
  switch (format) {
    case 'yyyy-MM-dd HH:mm:ss':
      result = `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`
      break
    case 'yyyy-MM-dd':
      result = `${yyyy}-${MM}-${dd}`
      break
    case 'HH:mm:ss':
      result = `${HH}:${mm}:${ss}`
      break
  }

  return result
}
