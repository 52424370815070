import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/config/elementLazyLoad.js'
import '@/assets/css/element-variables.scss'
import '@/config/globalDirective.js' // 全局指令
import '@/filters/index.js'
import '@/config/globalComponent.js' // 全局组件
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
