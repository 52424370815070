<!--
 * @FileDescription:table + 分页组件
 * @Author: 蔡林泽
 * @Date: 2021/1/5
 * @LastModifiedTime: 2021/1/6
   示例：<TableContainer :current="listForm.page" :total="total" :height="($store.state.common.clientHeight-208)+'px'" @change="pageChange">
 -->

<template>
  <div :style="{height: height}" class="table-container">
    <slot />
    <div class="footer f-b-c">
      <div>
        <slot name="action" />
      </div>
      <el-pagination :current="current" :page-sizes="['20', '30', '50', '100']" :page-size="defaultPageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableContainer',
  props: {
    height: {
      type: String,
      default: '100%'
    },
    total: {
      type: [Number, String],
      default: 0
    },
    defaultPageSize: {
      type: Number,
      default: 20
    },
    current: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      page: this.current,
      pageSize: this.defaultPageSize
    }
  },
  methods: {
    currentChange(page) {
      this.page = page
      this.$emit('change', this.page, this.pageSize)
    },
    sizeChange(pageSize) {
      this.pageSize = pageSize
      this.$emit('change', this.page, this.pageSize)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  position: relative;
  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 55px;
    padding: 0 20px;
    background: #ffffff;
    box-shadow: 0px -3px 8px rgba(175, 187, 202, 0.14);
    border-radius: 0px 4px 4px 0px;
    z-index: 999;
  }
}
</style>
