<!--
 * @FileDescription: spu/sku 信息；（图片，产品名称，spu/sku 编码）（用于table中）
 * @Author: 苏子龙
 * @Date: 2021/4/24
 -->
 <!--
  import Info from '@/components/commodity/Info'

  // spu
  <info img="" name="夏日家居女装短袖套装" code="GGGF" />

  // sku
  <info img="" name="夏日家居女装短袖套装" code="GGGF" type="sku">
    <p v-for="item in attributes" :key="item.key">
      {{ item.key }}: {{ item.value }}
    </p>
  </info>
 -->
<template>

  <div class="info">

    <el-tooltip placement="right-start" effect="light">
      <div class="img-box p-r">
        <img v-defaultImg class="img" :src="img">
        <div v-if="isNew" class="new"><span>{{ text }}</span></div>
      </div>
      <template slot="content">
        <img v-if="img" v-imgError class="pointer" style="max-width: 300px" :src="img" @click="openNewWindow(img)">
        <span v-else>此SKU还未维护图片<br>请联系对应开发</span>
      </template>
    </el-tooltip>
    <div>
      <p class="ellipsis" :title="name">{{ name }}</p>

      <el-tooltip v-if="type === 'sku'" trigger="click" placement="bottomLeft">
        <copy :width="'150px'" :text="code" class="pointer" style="text-decoration:underline" />
        <div slot="content" class="attributes">
          <slot />
        </div>
      </el-tooltip>

      <copy v-else :width="'150px'" :text="code" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'info',
  directives: {
    imgError: {
      inserted(el) {
        el.onerror = () => {
          el.parentElement.innerHTML = '<span>此SKU图片已失效<br>请联系对应开发</span>'
        }
      }
    }
  },
  props: {
    // spu/sku图片
    img: {
      type: String,
      default: ''
    },
    // spu/sku 名称
    name: {
      type: String,
      default: ''
    },
    // spu/sku 编码
    code: {
      type: String,
      default: ''
    },
    // 类型：spu/sku; sku则会有气泡卡片，卡片中的内容使用默认插槽
    type: {
      type: String,
      default: 'spu'
    },
    // 是否新品
    isNew: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: '新'
    }
  },
  methods: {
    openNewWindow(path) {
      // 部分图片会限制宽高，截取掉
      if (path.indexOf('?') > -1) {
        path = path.slice(0, path.indexOf('?'))
      }
      path && window.open(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.info{
  display: flex;
  max-width: 230px;

  .img{
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  p{
    width: 170px;
    color: #404d66;
    margin-bottom: 5px;
  }
}
.img-box{
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 10px;
  overflow: hidden;
}
.new{
  position: absolute;
  width: 36px;
  height: 36px;
  right: -18px;
  top: -18px;
  background: #FF1E17;

  border-radius: 100px;
  font-size: 12px;
  line-height: 40px;
  span{
    color: white;
    zoom: .8;
    padding-left: 8px;
    line-height: 62px;
  }
}
</style>
