/**
 * 网盟订单页面接口
 */
import axios from '@/util/request'

const baseURL = process.env.VUE_APP_API_URL

export default {
  getOrdersList: params => axios({ baseURL, url: '/api/v1/orders', method: 'get', params }) // 订单列表 v1.2
  // getOrdersStats: params => axios({ baseURL, url: '/api/v1/order/stats', method: 'get', params }) // 订单统计数据 v1.2
}

