/**
 * 网盟用户接口
 */
import axios from '@/util/request'

const baseURL = process.env.VUE_APP_USER_URL

export default {
  userRegister: data => axios({ baseURL, url: '/api/v1/user/register', method: 'post', data }), // 用户注册
  userLogin: data => axios({ baseURL, url: '/api/v1/user/login', method: 'post', data }), // 用户登录
  userInfo: data => axios({ baseURL, url: '/api/v1/user/info', method: 'put', data }), // 用户信息修改
  userLogout: data => axios({ baseURL, url: '/api/v1/user/logout', method: 'post', data }), // 用户退出
  getUserInfo: params => axios({ baseURL, url: '/api/v1/user/info', method: 'get', params }), // 获取用户信息
  forget_password: data => axios({ baseURL, url: '/api/v1/user/forget_password', method: 'post', data }), // 忘记密码发送邮件
  check_code: data => axios({ baseURL, url: '/api/v1/user/check_code', method: 'post', data }), // 忘记密码验证code
  confirm_password: data => axios({ baseURL, url: '/api/v1/user/confirm_password', method: 'post', data }) // 忘记密码-确认修改
}

