<!--
 * @FileDescription:网盟用户端官网
 * @Author: 蔡林泽
 * @Date: 2021/2/12
 * @LastModifiedTime: 2021/2/12
 -->
<template>
  <WebsiteNavigation>
    <template slot="content">
      <div class="start">
        <div class="f-a width">
          <div class="fd-c f-c">
            <span>Start Earning Money With KakaClo Affiliate Program Today!</span>
            <span>Sign up for the Free KakaClo Affiliate Program and get 2% of the revenues on all the purchases made by your referral customers for an entire year.</span>
            <div>
              <!-- <el-input v-model="email" size="large" placeholder="Enter your email" style="width: 306px;margin-right:8px;position:relative;top:0.5px;" /> -->
              <router-link :to="`/register`">
                <el-button class="pointer" size="medium" type="primary" style="height: 49px;width: 140px;">Register</el-button>
              </router-link>
              <el-button class="pointer" size="medium" style="height: 49px;width: 140px;margin-left:10px" @click="jumpOpen('https://kakaclo.com/')">KakaClo</el-button>
            </div>
          </div>
          <div>
            <img src="@/assets/img/组6041.png" style="width:650px;height:550px;">
          </div>
        </div>
      </div>
      <div class="width f-c">
        <div class="earn fd-c f-c-c">
          <span>Earn Money Anywhere Anytime, With KakaClo</span>
          <span>Not just a one-time commission, but an annual income</span>
        </div>
      </div>
      <div class="width">
        <div class="let f-a">
          <div class="fd-c f-c let1">
            <span>Let’s do the math quickly. If you make a referral to a dropshipper who generates $10,000 per day</span>
            <span style="font-size:20px;">in this case, your income for this successful<br> referral for an entire year is</span>
            <span style="font-weight:400">365 x $200 = <span style="color:#99C41C;font-size:35px;">$73,000</span></span>
          </div>
          <div class="fd-c f-c let2">
            <img src="@/assets/img/组6005.png">
            <span>When you make 10 referrals in total (which is the minimum amount to withdraw your commission) Your total annual income is $73,000 x 10 = $730,000.</span>
          </div>
        </div>
      </div>
      <div class="width">
        <div class="partner f-a">
          <div style="width: 653px;height: 445px;">
            <img src="@/assets/img/组 6035.svg">
          </div>
          <div class="fd-c f-c">
            <span>Why Partner With KakaClo?</span>
            <span style="font-size:20px;">As the industry-leading apparel supply chain platform, KakaClo is every clothing seller’s one-stop apparel factory. <br>If you join our affiliate program, you are going to get reliable partnership, dedicated support,timely payments, marketing materials, accurate tracking etc. .</span>
          </div>
        </div>
      </div>
      <div class="width">
        <div class="collapse f-b-c">
          <h1>How Does it Work?</h1>
          <el-collapse v-model="activeNames" accordion @change="handleChange">
            <el-collapse-item name="1">
              <template slot="title">
                <i :class="['header','iconfont',activeNames!=='1'?'icon-a-add-line1':'icon-subtract-line']" style="font-size:25px;margin-right:10px;" />
                <span style="font-size:28px;"> Apply online</span>
              </template>
              <span style="font-size:18px;">
                Sign up for a free account and get a special<br> affiliate link that you can share.
              </span>
            </el-collapse-item>
            <el-collapse-item name="2">
              <template slot="title">
                <i :class="['header','iconfont',activeNames!=='2'?'icon-a-add-line1':'icon-subtract-line']" style="font-size:25px;margin-right:10px;" />
                <span style="font-size:28px;">Promote KakaClo</span>
              </template>
              <span style="font-size:18px;">
                Spread the word using our pre-designed marketing materials.
              </span>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template slot="title">
                <i :class="['header','iconfont',activeNames!=='3'?'icon-a-add-line1':'icon-subtract-line']" style="font-size:25px;margin-right:10px;" />
                <span style="font-size:28px;"> Get paid</span>
              </template>
              <span style="font-size:18px;">
                Earn 2% each time your referral makes a purchase<br> for an entire year.
              </span>
            </el-collapse-item>
          </el-collapse>
          <div style="width:705px;height:437px;">
            <img v-show="activeNames==='1'" src="@/assets/img/组6024.svg">
            <img v-show="activeNames==='2'" src="@/assets/img/组 6025.svg">
            <img v-show="activeNames==='3'" src="@/assets/img/组 6033.svg">
          </div>
        </div>
      </div>
      <div class="people">
        <div class="width fd-c f-a-c">
          <h1>What People Say About KakaClo</h1>
          <div class="f-b">
            <div class="box fd-c f-b">
              <img src="@/assets/img/图像 212@2x.png">
              <span>KakaClo is amazing for whoever is in the<br> fashion business. Simplicity is the way for<br> business efficiency and KakaClo’s perfect<br> integration with my Shopify store cuts down<br> hours of work easily.</span>
              <div>
                <div class="f">
                  <img src="@/assets/img/蒙版组 185.svg">
                  <div class="fd-c" style="margin-left:15px;">
                    <span style="line-height: 28px;">Anna Marina</span>
                    <el-rate :value="5" disabled />
                  </div>
                </div>
              </div>
            </div>
            <div class="box fd-c f-b" style="margin:0 40px;">
              <img src="@/assets/img/图像 212@2x.png">
              <span>Really professional and very helpful team, I <br>totally had no idea about what to do when I<br> started and the result made me so happy!<br> Happy that I made my decision to partner<br> with KakaClo for my fashion business.</span>
              <div>
                <div class="f">
                  <img src="@/assets/img/蒙版组 186.svg">
                  <div class="fd-c" style="margin-left:15px;">
                    <span style="line-height: 28px;">Josh Andersen</span>
                    <el-rate :value="5" disabled />
                  </div>
                </div>
              </div>
            </div>
            <div class="box fd-c f-b">
              <img src="@/assets/img/图像 212@2x.png">
              <span>At first, I thought dropshipping might be<br> troublesome, thanks to KakaClo, it’s almost<br> effortless. And what’s more, I don’t only run<br> my own online store to make money, I joined<br> their affiliate</span>
              <div>
                <div class="f">
                  <img src="@/assets/img/蒙版组 187.svg">
                  <div class="fd-c" style="margin-left:15px;">
                    <span style="line-height: 28px;">Glendalee Gurne</span>
                    <el-rate :value="5" disabled />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Asked">
        <div class="width" style="padding:0 25px;">
          <h1>Frequently Asked Questions</h1>
          <el-collapse v-model="active">
            <el-collapse-item name="1" style="margin:20px 0;">
              <template slot="title">
                <span>What is KakaClo?</span>
                <i :class="['header',active.includes('1')?'el-icon-minus':'el-icon-plus']" />
              </template>
              <div>KakaClo, an apparel platform with its own factory and integrated supply chain, provides apparel wholesale,  professional dropshipping and customization service.
              </div>
            </el-collapse-item>
            <el-collapse-item name="2">
              <template slot="title">
                <span>How to join KakaClo Affiliate Program?</span>
                <i :class="['header',active.includes('2')?'el-icon-minus':'el-icon-plus']" />
              </template>
              <div>Easy! All you need to do is simply register an affiliate account and then you will get a unique affiliate link, users who register through your affiliate link will be counted as your referrals and generate passive income for you once they start selling.</div>
            </el-collapse-item>
            <el-collapse-item name="3" style="margin:20px 0;">
              <template slot="title">
                <span>Does it cost me anything to become an affiliate?</span>
                <i :class="['header',active.includes('3')?'el-icon-minus':'el-icon-plus']" />
              </template>
              <div>The program is absolutely free to join,we do not charge anything from you.</div>
            </el-collapse-item>
            <router-link :to="'/faq'">
              <p class="pointer" style="margin-bottom:150px;">More Questions</p>
            </router-link>
          </el-collapse>
        </div>

      </div>
    </template>
  </WebsiteNavigation>
</template>

<script>
import WebsiteNavigation from '@/components/layout/WebsiteNavigation.vue'
// import API from '@/api/index'
export default {
  components: { WebsiteNavigation },
  data() {
    return {
      email: '',
      activeNames: '1',
      active: ['1']
    }
  },
  created() { },
  activated() { },
  methods: {
    handleChange(val) {
      if (!val) this.activeNames = '1'
    },
    jumpOpen(data) {
      window.open(data)
    }
  }

}
</script>

<style lang="scss" scoped>
.start {
  width: 100%;
  background: #f7f7f7;
  .fd-c {
    width: 602px;
    span:nth-child(1) {
      font-size: 50px;
      font-weight: bold;
      color: #121e26;
      line-height: 60px;
    }
    span:nth-child(2) {
      font-size: 20px;
      color: #606060;
      line-height: 30px;
      margin: 21px 0 65px 0;
    }
  }
}
.earn {
  width: 700px;
  margin: 80px 0 50px 0;
  span:nth-child(1) {
    font-size: 40px;
    font-weight: bold;
    color: #121e26;
    line-height: 60px;
    text-align: center;
  }
  span:nth-child(2) {
    font-size: 24px;
    color: #606060;
    line-height: 60px;
  }
}
.let {
  margin: 75px 0 112px 0;
  .let1 {
    width: 670px;
    margin-top: -100px;
    span:nth-child(1) {
      font-size: 30px;
      font-weight: bold;
      color: #121e26;
      line-height: 60px;
    }
    span:nth-child(2) {
      font-size: 22px;
      color: #606060;
      line-height: 40px;
      margin: 20px 0;
    }
    span:nth-child(3) {
      font-size: 24px;
      font-weight: bold;
      color: #606060;
      line-height: 40px;
    }
  }
  .let2 {
    width: 576px;
    span {
      color: #606060;
      line-height: 32px;
    }
  }
}
.partner {
  margin: 132px 0 147px 0;
  h1 {
    font-size: 36px;
    font-weight: bold;
    color: #121e26;
    line-height: 60px;
  }
  .fd-c {
    width: 564px;
    span:nth-child(1) {
      font-size: 30px;
      font-weight: bold;
      color: #121e26;
      line-height: 60px;
      margin-bottom: 28px;
    }
    span:nth-child(2) {
      font-size: 22px;
      color: #606060;
      line-height: 40px;
    }
  }
}
.collapse {
  position: relative;
  margin: 112px 0 150px 0;
  padding: 0px 25px;
  h1 {
    position: absolute;
    top: 0;
  }
  /deep/ .el-collapse {
    border: none;
    .el-collapse-item__header {
      border-bottom: none;
      height: 70px;
      line-height: 70px;
    }
  }
  /deep/.el-collapse-item__arrow {
    display: none;
  }
  /deep/ .el-collapse-item {
    padding-left: 10px;
    width: 600px;
    border-radius: 20px;
    .is-active {
      background: rgba(153, 196, 28, 0.07);
      border-radius: 20px 20px 0 0;
      color: #99c41c;
      .el-icon-minus {
        color: #99c41c !important;
      }
    }
  }
  /deep/ .el-collapse-item__wrap {
    background: rgba(153, 196, 28, 0.07);
    border-radius: 0 0 20px 20px;
    padding-left: 40px;
  }
  /deep/ .el-collapse-item__content {
    color: #99c41c;
  }
}
.people {
  width: 100%;
  background: #f7f7f7;
  // margin: 87px 0 147px 0;
  h1 {
    font-size: 40px;
    margin: 87px 0 99px 0;
  }
  /deep/ .el-rate__icon {
    margin-right: 0;
  }
  .box {
    width: 429px;
    height: 335px;
    background: #fff;
    padding: 15px 0 38px 36px;
    margin-bottom: 147px;
    > img {
      width: 77px;
      height: 59px;
      margin-left: -30px;
    }
    > span {
      line-height: 25px;
    }
  }
}
.Asked {
  // width: 1400px;
  // padding: 0 25px;
  h1 {
    font-size: 40px;
    font-weight: 500;
    color: #121e26;
    margin: 100px 0 40px 0;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    color: #99c41c;
  }
  /deep/.el-collapse {
    border: none;
  }
  /deep/.el-collapse-item__header {
    display: flex;
    justify-content: space-between;
    height: 70px;
    > span {
      font-size: 18px;
      font-weight: 500;
      color: #121e26;
    }
    .header {
      font-size: 16px;
      color: #7b60c4;
    }
    .el-icon-arrow-right {
      display: none;
    }
  }
  /deep/ .el-collapse-item__content {
    font-size: 14px;
    color: #606060;
  }
}

.width {
  width: 1400px;
  margin: 0 auto;
}
</style>
