import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
const message = Message
const toLogin = function() {
  store.commit('common/loginOut')
  message.error('please login again')
}

// 创建一个新的axios实例
const service = axios.create()

// 请求拦截器 => 处理token统一注入
service.interceptors.request.use(
  (config) => {
    if (store.state.common.userInfo.token) config.headers.token = store.state.common.userInfo.token
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 10006) {
      toLogin()
      return Promise.reject('please login again')
    }
    if (response.config.responseType !== 'blob' && response.data.code !== 10000) {
      // if (!response.config.notShowMessage)
      message.error(response.data.message)
      return Promise.reject(response.data)
    }
    return response.data
  },
  (error) => {
    message.error('The system slipped, please try again later')
    return Promise.reject(error.response)
  }
)

// 导出axios实例
export default service
