<!--
 * @FileDescription: MyLink 详情
 * @Author: 蔡林泽
 * @Date: 2021/12/30
 * @LastModifiedTime: 2021/12/30
 -->
<template>
  <!--MyLink 详情弹窗  -->
  <el-dialog title="Details" :visible.sync="changeVisible" width="1300px" top="15vh" :close-on-click-modal="false" :close-on-press-escape="false" @close="closeDialog">
    <el-descriptions style="text-alight:center;">
      <el-descriptions-item label="Page"><span>{{ list.name }}  <el-tag type="warning">{{ list.state === 0 ? 'End' : 'Ongoing' }}</el-tag></span></el-descriptions-item>
      <el-descriptions-item label="Referral Link">
        <copy :width="'200px'" :text="list.url" class="pointer" style="line-height: 18px;" />
      </el-descriptions-item>
    </el-descriptions>
    <!-- 折线图 -->
    <div class="content">
      <h1>Data Analysis</h1>
      <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="Start date" end-placeholder="End date" style="margin-right:20px;" :picker-options="pickerOptions" @change="pickerChange" />
      <el-radio-group v-model="listForm.type" size="medium" @change="groupChange">
        <el-radio-button label="hour">Hour</el-radio-button>
        <el-radio-button label="day">Day</el-radio-button>
        <el-radio-button label="week">Week</el-radio-button>
        <el-radio-button label="month">Month</el-radio-button>
      </el-radio-group>
      <!-- 折线图 -->
      <div id="echart-line" class="echart" :style="{width: '100%', height: '600px',marginTop:'20px'}" />
    </div>

  </el-dialog>
</template>

<script>
import API from '@/api/index'
import { dateFormat } from '@/util/date.js'
// echarts插件按需引入
import * as echarts from 'echarts/core' // 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口
import { LineChart } from 'echarts/charts' // 引入所需的图表，图表后缀都为 Chart
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent } from 'echarts/components' // 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import { CanvasRenderer } from 'echarts/renderers' // 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, LineChart, CanvasRenderer]) // 注册必须的组件

export default {
  data() {
    return {
      changeVisible: false,
      list: '',
      listForm: {
        type: 'day'
      },
      maxDate: '',
      minDate: '',
      // 业务逻辑 禁用天数设置
      Days: 30,
      pickerOptions: {
        onPick: ({
          maxDate,
          minDate
        }) => {
          this.maxDate = maxDate
          this.minDate = minDate
        },
        disabledDate: (date) => {
          return this.disabledDatecondition(date)
        }
      },
      time: '',
      getchart: ''
    }
  },
  created() {

  },
  methods: {
    showDialog(data) {
      this.changeVisible = true
      this.list = data
      this.$nextTick(() => {
        // 当前日期前一个月的时间戳
        let data = Date.now() - 30 * 24 * 60 * 60 * 1000
        this.time = [dateFormat(data, 'yyyy-MM-dd'), dateFormat(Date.now(), 'yyyy-MM-dd')]
        this.pickerChange(this.time)
      })
    },
    pickerChange(data) {
      if (data) {
        this.listForm.start = data[0]
        this.listForm.end = data[1]
        let parmas = Object.assign({}, this.listForm)
        parmas.id = this.list.id
        API.getPopularize_detail(parmas).then(res => {
          this.$nextTick(() => {
            this.initChart(res.data)
          })
        })
      } else {
        this.maxDate = ''
        this.minDate = ''
        this.listForm.start = undefined
        this.listForm.end = undefined
      }
    },
    groupChange() {
      if (Array.isArray(this.time)) this.time = undefined
      // 业务逻辑 ：时间颗粒度可选择 小时、天(可筛选一个月区间)、周(可筛选三个月区间)、月(可筛选12个月区间)
      this.listForm.type === 'day' ? this.Days = +30 : this.listForm.type === 'week' ? this.Days = +90 : this.listForm.type === 'month' ? this.Days = +365 : this.Days = 0
      this.initChart({})
      this.$message.warning('Please select the time')
    },
    disabledDatecondition(date) {
      let maxDate = this.maxDate
      let minDate = this.minDate
      let endTime = ''
      if (minDate) {
        // 此刻时间戳 - 筛选开始时间的 + 一个月时间戳
        let val = Date.now() - (new Date(this.minDate).valueOf() + this.Days * 24 * 60 * 60 * 1000)
        // 当天的时间戳                          筛选开始时间的时间戳
        val < 0 ? endTime = Date.now() - 60 * 60 * 1000 : endTime = (new Date(this.minDate).valueOf() + this.Days * 24 * 60 * 60 * 1000)
        // 当开始时间 和 结束时间都有值 时,不禁用状态，可再次筛选所有时间段
        if (minDate && maxDate) return date.valueOf() > Date.now() - 60 * 60 * 1000
        return date && (date.valueOf() > endTime || date.valueOf() < minDate.valueOf() - this.Days * 24 * 60 * 60 * 1000)
      } else {
        return date && (date.valueOf() > Date.now() - 60 * 60 * 1000)
      }
    },
    initChart(data) {
      let mainContainer = document.getElementById('echart-line')
      this.getchart = echarts.init(mainContainer)
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['PV', 'Registration', 'UV'],
          show: true,
          right: 10,
          align: 'right'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: Object.keys(data).length > 0 ? Object.values(data['data']) : ''
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'PV',
            type: 'line',
            data: data['pv']
          },
          {
            name: 'Registration',
            type: 'line',
            data: data['reg_num']
          },
          {
            name: 'UV',
            type: 'line',
            data: data['uv']
          }
        ]
      }

      option && this.getchart.setOption(option)
    },
    closeDialog() {
      this.initChart({})
      this.time = null
      this.listForm = this.$options.data().listForm
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  > h1 {
    font-size: 18px;
    line-height: 50px;
    color: #1b1c31;
  }
}
/deep/ .el-descriptions-item__container .el-descriptions-item__label{
      line-height: 23px;
}
</style>
