import router from '@/router/index.js'
const state = {
  userInfo: {},
  clientHeight: 0
}

const mutations = {
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  setUsername(state, username) {
    state.userInfo.username = username
  },
  loginOut(state, userInfo) {
    state.userInfo = {}
    router.replace('/login')
  },
  setClientHeight(state) {
    state.clientHeight = window.innerHeight
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
