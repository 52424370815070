<!--
 * @FileDescription:网盟客户端登录页
 * @Author: 蔡林泽
 * @Date: 2021/12/27
 * @LastModifiedTime: 2021/12/27
 -->
<template>
  <div class="container f-c-c">
    <div class="box f-b-c">
      <div class="left-box f-c-c">
        <img class="pointer" src="~@/assets/img/login.svg" @click="windowOpen('/promote')">
      </div>
      <div class="right-box f-c-c">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
          <el-form-item class="f-c-c">
            <img class="pointer" src="@/assets/img/logo-login.svg" style="width:175px;" @click="windowOpen('/promote')">
          </el-form-item>
          <el-form-item label="Email ID" prop="email">
            <el-input v-model="ruleForm.email" style="width:310px" placeholder="Email" maxlength="100" />
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input v-model="ruleForm.password" type="password" style="width:310px" placeholder="password" maxlength="100" />
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" style="width:310px" @click="submitForm('ruleForm')">Login</el-button>
            <router-link :to="'/forgotpassword'"><span>Forgot Password</span></router-link>
            <router-link :to="'/register'">
              <el-button size="medium" style="width:310px">Register</el-button>
            </router-link>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <a class="copyright" href="/login">Copyright ©2021 All Rights Reserved By KakaClo</a>
  </div>
</template>

<script>
import API from '@/api/index'
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        email: [{ required: true, message: 'This field is required', trigger: 'blur' }, { type: 'email', message: 'Please enter the correct Email address', trigger: ['blur'] }],
        password: [{ required: true, message: 'This field is required', trigger: 'blur' }]
      }
    }
  },
  created() { },
  activated() { },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        let params = new FormData()
        params.append('email', this.ruleForm.email)
        params.append('password', this.ruleForm.password)
        API.userLogin(params).then(res => {
          this.$store.commit('common/setUserInfo', res.data)
          this.$router.push({ path: '/' })
          localStorage.setItem('active', '1')
        })
      })
    },
    windowOpen(url) {
      window.open(url)
    }
  }
}

</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  .box {
    background: #fff;
    box-sizing: border-box;
    .left-box {
      width: 608px;
      height: 608px;
      img {
        width: 480px;
        height: 480px;
      }
    }
    .right-box {
      width: 608px;
      height: 608px;
      i,
      p {
        font-size: 20px;
        text-align: center;
      }
      span {
        display: block;
        color: #99C41C;
        line-height: 70px;
        text-align: end;
        cursor: pointer;
      }
    }
  }
  .copyright {
    position: fixed;
    bottom: 10px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    text-decoration:none;
  }
}
</style>
