/**
 * 网盟用户页面接口
 */
import axios from '@/util/request'

const baseURL = process.env.VUE_APP_API_URL

export default {
  getListUsers: params => axios({ baseURL, url: '/api/v1/customers', method: 'get', params }), // 邀请用户列表 v1.2
  getUserStatistics: params => axios({ baseURL, url: '/api/v1/customer/stats', method: 'get', params }) // 邀请用户数据统计 v1.2
}

