/**
 * 通用接口
 */
import axios from '@/util/request'

const baseURL = process.env.VUE_APP_API_URL

export default {
  accountSetting: data => axios({ baseURL, url: '/api/store/accountSetting', method: 'POST', data }) // 平台下拉框数据
}
