<!--
 * @FileDescription: 网盟官网固定导航栏
 * @Author: 蔡林泽
 * @Date: 2022/2/12
 -->
<template>
  <div class="container">
    <div class="header" :style="{ background: changeColor ? '#ffffff':'#f7f7f7', boxShadow: changeShadow ?'0px 3px 20px 0px rgba(0,0,0,0.11)':''}">
      <div class="f-b-c">
        <router-link :to="'/promote'">
          <img src="@/assets/img/logo-login.svg" style="width:220px;">
        </router-link>
        <div>
          <el-button class="pointer" size="medium" type="text" style="margin-right:25px;" @click="open('/login')">Login</el-button>
          <el-button class="pointer" size="medium" type="primary" @click="open('/register')">Register</el-button>
        </div>
      </div>
    </div>
    <!-- 插槽 -->
    <div class="content">
      <slot name="content" />
    </div>
    <div class="footer">
      <div class="slogan">
        <div class="f-c-c fd-c" style="height:300px;">
          <span>
            Believe It<br>
            Start Your Promotion Journey Now
          </span>
          <div style="margin-top:30px;">
            <el-input v-model="input" size="large" placeholder="Enter your email" style="width: 306px;margin-right:8px;position:relative;top:0.5px;" />
            <router-link :to="`/register?email=${input}`">
              <el-button class="pointer" size="medium" style="height: 50px;width: 140px;color:#99C41C">Register</el-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="Navigation f-b">
        <div class="left">
          <div class="f-c fd-c">
            <div>
              <img src="@/assets/img/logo-login.svg" style="width:200px;">
            </div>
            <div class="f-b">
              <i class="iconfont icon-mail-send-line" style="font-size: 20px;margin-right:10px;margin-left:3px;" />
              <span> support@KakaClo.com</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="f-a-c">
            <div class="fd-c f-a">
              <span class="pointer" @click="open('https://kakaclo.com/')">KakaClo</span>
            </div>
            <span style="color:rgb(112, 112, 112,0.6);font-size:12px;">|</span>
            <div class="fd-c f-a">
              <span class="pointer" @click="open('https://www.kakaclo.com/about-us/')">About Us</span>
            </div>
            <span style="color:rgb(112, 112, 112,0.6);font-size:12px;">|</span>
            <div class="fd-c f-a">
              <span class="pointer" @click="open('/programTerm')">Program Terms</span>
            </div>
            <span style="color:rgb(112, 112, 112,0.6);font-size:12px;">|</span>
            <div class="fd-c f-a">
              <span class="pointer" @click="open('/privacypolicy')">Privacy Policy</span>
            </div>
            <span style="color:rgb(112, 112, 112,0.6);font-size:12px;">|</span>
            <div class="fd-c f-a">
              <router-link :to="'/faq'">
                <span>FAQ</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="dav">
        <span>Copyright ©2021 All Rights Reserved By KakaClo</span>
      </div>
    </div>
  </div>
</template>

<script>
// import API from '@/api/index'
import _ from 'lodash'
export default {
  data() {
    return {
      input: '',
      changeColor: true,
      changeShadow: false
    }
  },
  created() {
    if (this.$route.path === '/promote') this.changeColor = false
  },
  activated() { },
  mounted() {
    this.initScroll()
  },
  beforeDestroy() {
    try {
      window.removeEventListener('scroll', this.initScroll)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    initScroll() {
      this.$nextTick(() => {
        window.addEventListener('scroll', _.throttle(e => {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          scrollTop === 0 ? this.changeShadow = false : this.changeShadow = true
          scrollTop === 0 && this.$route.path === '/promote' ? this.changeColor = false : this.changeColor = true
        }, 15))
      })
    },
    open(url) {
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #ffffff;
  /deep/ .el-input__inner {
    height: 50px;
  }
  /deep/ .el-button--medium{
    font-size: 16px;
  }
  .header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    .f-b-c {
      width: 1400px;
      height: 80px;
      margin: 0 auto;
    }
  }
  .content {
    padding-top: 80px;
    width: 100%;
  }
  .footer {
    // width: 1400px;
    margin: 0 auto;
    .slogan {
      background: #99C41C;
      span {
        width: 722px;
        font-size: 40px;
        font-weight: 500;
        color: #ffffff;
        line-height: 60px;
        text-align: center;
      }
    }
    .Navigation {
      width: 1400px;
      margin: 0 auto;
      height: 240px;
      padding-left: 40px;
      .left {
        .f-c {
          height: 240px;
        }
        .f-b {
          width: 175px;
        }
      }
      .right {
        width: 500px;
        span {
          line-height: 25px;
        }
        span:nth-child(1) {
          font-size: 16px;
          color: #606060;
          line-height: 50px;
        }
        .main1 {
          font-size: 16px;
          font-weight: 500;
          color: #121e26;
        }
        .f-a-c {
          height: 240px;
        }
      }
    }
    .dav {
      width: 1400px;
      margin: 0 auto;
      text-align: center;
      padding: 17px 0;
      border-top: 1px solid #dddcdf;
      span {
        font-size: 16px;
        font-weight: 500;
        color: #121e26;
        line-height: 40px;
      }
    }
  }
}
</style>
