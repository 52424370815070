<template>
  <div class="layout">
    <!-- 左侧菜单 -->
    <div class="menu bg-white">
      <!-- logo -->
      <div class="logo f-c-c pointer">
        <router-link :to="'/promote'">
          <img src="@/assets/img/logo-white.svg" style="height:35px;">
        </router-link>
      </div>
      <!-- 菜单 -->
      <el-menu :default-active="active" class="el-menu-vertical-demo" background-color="#111827" text-color="#fff" @select="handleOpen">
        <el-menu-item index="1">
          <i class="iconfont icon-pie-chart-2-line" style="margin-right: 10px;font-size: 20px;" />
          <span slot="title">Dashboard</span>
        </el-menu-item>
        <el-menu-item index="2">
          <i class="iconfont icon-links-line" style="margin-right: 10px;font-size: 20px;" />
          <span slot="title">My Link</span>
        </el-menu-item>
        <el-menu-item index="3">
          <i class="iconfont icon-user-search-line" style="margin-right: 10px;font-size: 20px;" />
          <span slot="title">Customer</span>
        </el-menu-item>
        <el-menu-item index="4">
          <i class="iconfont icon-a-file-list-3-line1" style="margin-right: 10px;font-size: 20px;" />
          <span slot="title">Orders</span>
        </el-menu-item>
        <el-menu-item index="5">
          <i class="iconfont icon-bank-card-line" style="margin-right: 10px;font-size: 20px;" />
          <span slot="title">Balance</span>
        </el-menu-item>
      </el-menu>
    </div>
    <!-- header -->
    <div class="header f-b-c">
      <div class="user pointer">
        <el-dropdown @command="handleCommand">
          <span>
            {{ $store.state.common.userInfo.username }}<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="Profile">My Profile</el-dropdown-item>
            <el-dropdown-item command="logOut">Log Out</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <router-view />

    <!--My Profile 弹窗  -->
    <el-dialog title="Account Settings" :visible.sync="dialogVisible" width="600px" top="30vh" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="right" label-width="120px">
        <el-form-item label="User ID：" prop="id">
          {{ ruleForm.id }}
        </el-form-item>
        <el-form-item label="Full Name：" prop="name">
          <el-input v-model="ruleForm.name" style="width:310px" maxlength="100" />
        </el-form-item>
        <el-form-item label="Email：" prop="email">
          <el-input v-model="ruleForm.email" style="width:310px" maxlength="100" />
        </el-form-item>
        <el-form-item label="Password：" prop="" class="fontStyle">
          <!-- <el-input v-model="ruleForm.password" :type="isShow?'':'password'" style="width:310px" disabled />
          <i v-if="!isShow" class="iconfont iconbiyanjing" @click="isShow=true" />
          <i v-else class="iconfont iconyanjing" @click="isShow=false" /> -->
          <span @click="Change">Change</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">Save</el-button>
      </span>
    </el-dialog>

    <!--Change Password 弹窗  -->
    <el-dialog title="Change Password" :visible.sync="changePasswordVisible" width="700px" top="30vh" :close-on-click-modal="false" :close-on-press-escape="false" @close="closeDialog">
      <el-form ref="formData" :model="formData" :rules="formRules" label-position="top" class="formStyle">
        <el-form-item class="fontStyle">
          <p>A Verification code has been sent to your email <span style="color:#99C41C">{{ formData.email }}</span></p>
        </el-form-item>
        <el-form-item label="Verification Code" prop="code">
          <el-input v-model="formData.code" style="width:495px;" maxlength="100">
            <el-button slot="append" :disabled="!isNaN(countdown)" @click="Send"><span style="color:#99C41C"> {{ countdown }} </span></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input v-model="formData.password" type="password" autocomplete="off" style="width:495px;" maxlength="100" />
        </el-form-item>
        <el-form-item label="Confirm Password" prop="confirmpwd">
          <el-input v-model="formData.confirmpwd" type="password" autocomplete="off" style="width:495px;" maxlength="100" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePasswordVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submit('formData')">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/api/index'
export default {
  data() {
    const passwordVerify = (rule, value, callback) => {
      !value ? callback(new Error('This field is required')) : callback()
    }
    const checkPassVerify = (rule, value, callback) => {
      if (!value) callback(new Error('This field is required'))
      value !== this.formData.password ? callback(new Error('The two passwords are inconsistent!')) : callback()
    }
    return {
      dialogVisible: false,
      changePasswordVisible: false,
      ruleForm: {},
      rules: {
        email: [{ required: true, message: 'This field is required', trigger: 'blur' }, { type: 'email', message: 'Please enter your vaild email', trigger: ['blur'] }],
        name: [{ required: true, message: 'This field is required', trigger: 'blur' }]
      },
      formData: {},
      formRules: {
        code: [{ required: true, message: 'This field is required', trigger: 'blur' }],
        password: [
          { validator: passwordVerify, trigger: 'blur' }
        ],
        confirmpwd: [
          { validator: checkPassVerify, trigger: 'blur' }
        ]
      },
      // isShow: false,
      countdown: 'Send',
      timer: null,
      active: localStorage.getItem('active') || '1'
    }
  },
  methods: {
    logOut() {
      API.userLogout().then(res => {
        this.$store.commit('common/loginOut')
      })
    },
    Change() {
      API.getUserInfo().then(res => {
        this.formData.email = res.data.email
        this.changePasswordVisible = true
      })
    },
    Profile() {
      API.getUserInfo().then(res => {
        let data = res.data
        this.ruleForm = {
          id: data.id,
          name: data.username,
          email: data.email
        }
        this.dialogVisible = true
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        let params = new FormData()
        params.append('email', this.ruleForm.email)
        params.append('username', this.ruleForm.name)
        API.userInfo(params).then(res => {
          this.dialogVisible = false
          this.$store.commit('common/setUsername', this.ruleForm.name)
          this.$message.success('Save success')
        })
      })
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        let params = new FormData()
        params.append('code', this.formData.code)
        params.append('email', this.formData.email)
        API.check_code(params).then(res => {
          let data = new FormData()
          data.append('email', this.formData.email)
          data.append('password', this.formData.password)
          data.append('confirmpwd', this.formData.confirmpwd)
          API.confirm_password(data).then(res => {
            this.dialogVisible = false
            this.changePasswordVisible = false
            this.$message.success('Save success')
          })
        })
      })
    },
    Send() {
      let params = new FormData()
      params.append('email', this.formData.email)
      API.forget_password(params).then(res => {
        this.$message.success('Sent successfully')
        const TIME_COUNT = 60 // 更改倒计时时间
        if (!this.timer) {
          this.countdown = TIME_COUNT
          this.timer = setInterval(() => {
            if (this.countdown > 0 && this.countdown <= TIME_COUNT) {
              this.countdown--
            } else {
              clearInterval(this.timer) // 清除定时器
              this.timer = null
              this.countdown = 'Send'
            }
          }, 1000)
        }
      })
    },
    closeDialog() {
      this.$refs.formData.resetFields()
      // this.isShow = false
    },
    handleCommand(command) {
      this[command]()
    },
    handleOpen(value) {
      localStorage.setItem('active', value)
      this.active = value
      switch (value) {
        case '1':
          return this.$router.push('/dashboard')
        case '2':
          return this.$router.push('/mylink')
        case '3':
          return this.$router.push('/customer')
        case '4':
          return this.$router.push('/orders')
        case '5':
          return this.$router.push('/balance')
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100vh;
  padding: 76px 12px 12px 220px;
}
.menu {
  background: #111827;
  position: fixed;
  top: 0;
  left: 0;
  width: 208px;
  height: 100vh;
  z-index: 10;
  /deep/ .el-menu {
    border-right: none;
  }
  .logo {
    height: 64px;
  }
}
.header {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100vw - 208px);
  height: 64px;
  background: white;
  z-index: 999;
}
.user {
  position: absolute;
  right: 25px;
  top: 20px;
}
.mr5 {
  margin-right: 3px;
}
.fontStyle {
  span {
    color: #99c41c;
    cursor: pointer;
  }
  i {
    color: #99c41c;
    cursor: pointer;
    padding: 0 15px;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    color: #595959;
    word-wrap: break-word;
    width: 320px;
    text-align: start;
    line-height: 21px;
  }
}
</style>
