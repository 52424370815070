/**
 * 网盟Balance页面接口
 */
import axios from '@/util/request'

const baseURL = process.env.VUE_APP_API_URL

export default {
  getUserfunds: params => axios({ baseURL, url: '/api/v1/user/funds', method: 'get', params }), // 用户资金信息 v1.2
  getUserInvite_stats: params => axios({ baseURL, url: '/api/v1/user/invite_stats', method: 'get', params }), // 已邀请用户数 v1.2
  addtransactionMethod: data => axios({ baseURL, url: '/api/v1/user/transaction_method', method: 'post', data }), // 添加交易方式 v1.2
  gettransactionMethod: params => axios({ baseURL, url: '/api/v1/user/transaction_methods', method: 'get', params }), // 获取交易方式 v1.2
  deleteDeal: id => axios({ baseURL, url: `/api/v1/transaction_method/${id}`, method: 'delete' }), // 删除交易方式 v1.2
  withdrawal: data => axios({ baseURL, url: '/api/v1/user/withdraw', method: 'post', data }), // 发起提现
  sendCode: data => axios({ baseURL, url: '/api/v1/user/withdraw/send_code', method: 'post', data }), // 发送提现验证码 v1.2
  cancelWithdrawal: id => axios({ baseURL, url: `/api/v1/user/withdraw/${id}/cancel`, method: 'put' }), // 取消提现 v1.2
  getWithdrawsList: params => axios({ baseURL, url: '/api/v1/user/withdraws', method: 'get', params }), // 提现记录 v1.2
  getTransactions: params => axios({ baseURL, url: '/api/v1/user/transactions', method: 'get', params }), // 交易记录 v1.2
  getWithdrawDetail: id => axios({ baseURL, url: `/api/v1/user/withdraw/${id}/detail`, method: 'get' }), // 提现详情 v1.2
  exportTransaction: data => axios({ baseURL, url: '/api/v1/user/transaction/export', method: 'post', data, responseType: 'blob' }), // 交易记录导出 v1.2
  exportWithdraw: data => axios({ baseURL, url: '/api/v1/user/withdraw/export', method: 'post', data, responseType: 'blob' }) // 提现记录导出 v1.2
}

