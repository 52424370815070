/* Vue自定义指令 */

/**
 * 图片出现异常或没有图片时使用默认图片
 * v-defaultImg
 * v-defaultImg="require('@/assets/img/default.png')"
*/
export default {
  inserted(el, options) {
    el.onerror = () => {
      el.src = options.value || require('@/assets/img/defaultImg.png')
    }
  }
}
