<!--
 * @FileDescription:网盟客户端忘记密码
 * @Author: 蔡林泽
 * @Date: 2021/12/27
 * @LastModifiedTime: 2021/12/27
 -->
<template>
  <div class="container f-c-c">
    <div class="box f-b-c">
      <div class="left-box f-c-c">
        <img class="pointer" src="~@/assets/img/login.svg" @click="windowOpen('/promote')">
      </div>
      <div class="right-box f-c-c">
        <el-form v-if="isTrue" ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
          <el-form-item>
            <p>Forgot Password</p>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="ruleForm.email" style="width:310px" placeholder="Email" maxlength="100" />
          </el-form-item>
          <el-form-item label="Verification Code" prop="code">
            <el-input v-model="ruleForm.code" style="width:310px" maxlength="100">
              <el-button slot="append" size="medium" :disabled="!isNaN(countdown)" @click="Send('ruleForm')"><span style="color:#99C41C"> {{ countdown }} </span></el-button>
            </el-input>
          </el-form-item>
          <el-form-item>
            <p v-show="hideEmail" class="fontStyle">The verification code will be sent to your email {{ hideEmail }}</p>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" style="width:310px" @click="submitForm('ruleForm')">Next</el-button>
          </el-form-item>
          <el-form-item style="width:310px">
            <i class="iconfont iconarrow-go-back-line" style="display:block;color:#898989;cursor: pointer;text-align:center;" @click="$router.go(-1)"> Back</i>
          </el-form-item>
        </el-form>
        <el-form v-else ref="formData" :model="formData" :rules="formRules" label-position="top">
          <el-form-item>
            <p>Register</p>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input v-model="formData.password" type="password" autocomplete="off" maxlength="100" />
          </el-form-item>
          <el-form-item label="Confirm Password" prop="confirmpwd">
            <el-input v-model="formData.confirmpwd" type="password" autocomplete="off" maxlength="100" />
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" style="width:310px" @click="Sure('formData')">Submit</el-button>
          </el-form-item>
          <el-form-item style="width:310px">
            <i class="iconfont iconarrow-go-back-line" style="display:block;color:#898989;cursor: pointer;text-align:center;" @click="isTrue=true"> Back</i>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <a class="copyright" href="/login">Copyright ©2021 All Rights Reserved By KakaClo</a>
  </div>
</template>

<script>
import API from '@/api/index'
import { regEmail } from '@/util/common.js'

export default {
  data() {
    const passwordVerify = (rule, value, callback) => {
      !value ? callback(new Error('This field is required')) : callback()
    }
    const checkPassVerify = (rule, value, callback) => {
      if (!value) callback(new Error('This field is required'))
      value !== this.formData.password ? callback(new Error('The two passwords are inconsistent!')) : callback()
    }
    return {
      ruleForm: {},
      formData: {},
      rules: {
        email: [{ required: true, message: 'This field is required', trigger: 'blur' }, { type: 'email', message: 'Please enter your vaild email', trigger: ['blur'] }],
        code: [{ required: true, message: 'This field is required', trigger: 'blur' }]
      },
      formRules: {
        password: [
          { validator: passwordVerify, trigger: 'blur' }
        ],
        confirmpwd: [
          { validator: checkPassVerify, trigger: 'blur' }
        ]
      },
      countdown: 'Send',
      isTrue: true,
      timer: null,
      hideEmail: ''
    }
  },
  created() { },
  activated() { },
  methods: {
    windowOpen(url) {
      window.open(url)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        let params = new FormData()
        params.append('code', this.ruleForm.code)
        params.append('email', this.ruleForm.email)
        API.check_code(params).then(res => {
          this.isTrue = false
        })
      })
    },
    Sure(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        let params = new FormData()
        params.append('email', this.ruleForm.email)
        params.append('password', this.formData.password)
        params.append('confirmpwd', this.formData.confirmpwd)
        API.confirm_password(params).then(res => {
          this.$message.success('Successfully modified')
          setTimeout(() => {
            this.$router.push({
              path: '/login'
            })
          }, 500)
        })
      })
    },
    Send(formName) {
      // 发送验证码 只对Email校验
      this.$refs[formName].validateField('email', (valid) => {
        if (valid !== '') return false
        let params = new FormData()
        params.append('email', this.ruleForm.email)
        API.forget_password(params).then(res => {
          const TIME_COUNT = 60 // 更改倒计时时间
          if (!this.timer) {
            this.hideEmail = regEmail(this.ruleForm.email)
            this.countdown = TIME_COUNT
            this.timer = setInterval(() => {
              if (this.countdown > 0 && this.countdown <= TIME_COUNT) {
                this.countdown--
              } else {
                clearInterval(this.timer) // 清除定时器
                this.timer = null
                this.countdown = 'Send'
              }
            }, 1000)
          }
        })
      })
    }
  }

}

</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  .box {
    background: #fff;
    box-sizing: border-box;
    .left-box {
      width: 608px;
      height: 608px;
      img {
        width: 480px;
        height: 480px;
      }
    }
    .right-box {
      width: 608px;
      height: 608px;
      p {
        font-size: 20px;
        text-align: center;
      }
      .fontStyle {
        font-size: 14px;
        color: #595959;
        word-wrap: break-word;
        width: 310px;
        text-align: start;
        line-height: 21px;
      }
    }
  }
  .copyright {
    position: fixed;
    bottom: 10px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    text-decoration:none;
  }
}
</style>
