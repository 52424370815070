<!--
 * @FileDescription:网盟客户端注册页
 * @Author: 蔡林泽
 * @Date: 2021/12/27
 * @LastModifiedTime: 2021/12/27
 -->
<template>
  <div class="container f-c-c">
    <div class="box f-b-c">
      <div class="left-box f-c-c">
        <img class="pointer" src="~@/assets/img/login.svg" @click="windowOpen('/promote')">
      </div>
      <div class="right-box f-c-c">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
          <el-form-item>
            <p>Register</p>
          </el-form-item>
          <el-form-item label="Full Name" prop="username">
            <el-input v-model="ruleForm.username" style="width:310px" placeholder="Full Name" maxlength="100" />
          </el-form-item>
          <el-form-item label="Email ID" prop="email">
            <el-input v-model="ruleForm.email" style="width:310px" placeholder="Email" maxlength="100" />
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input v-model="ruleForm.password" type="password" style="width:310px" placeholder="password" maxlength="100" />
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" style="width:310px" @click="submitForm('ruleForm')">Register</el-button>
          </el-form-item>
          <el-form-item>
            <router-link :to="'/login'">
              <el-button size="medium" style="width:310px">Login</el-button>
            </router-link>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <a class="copyright" href="/login">Copyright ©2021 All Rights Reserved By KakaClo</a>
  </div>
</template>

<script>
import API from '@/api/index'
export default {
  name: '/register',
  data() {
    return {
      ruleForm: {},
      rules: {
        username: [{ required: true, message: 'This field is required', trigger: 'blur' }],
        email: [{ required: true, message: 'This field is required', trigger: 'blur' }, { type: 'email', message: 'Please enter your vaild email', trigger: ['blur'] }],
        password: [{ required: true, message: 'This field is required', trigger: 'blur' }]
      }
    }
  },
  created() {
    if (this.$route.query.email) this.ruleForm.email = this.$route.query.email
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        let params = new FormData()
        params.append('username', this.ruleForm.username)
        params.append('email', this.ruleForm.email)
        params.append('password', this.ruleForm.password)
        API.userRegister(params).then(res => {
          this.$message.success('success')
          setTimeout(() => {
            this.$router.push({
              path: '/login'
            })
          }, 1000)
        })
      })
    },
    windowOpen(url) {
      window.open(url)
    }
  }
}

</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  .box {
    background: #fff;
    box-sizing: border-box;
    .left-box {
      width: 608px;
      height: 608px;
      img {
        width: 480px;
        height: 480px;
      }
    }
    .right-box {
      width: 608px;
      height: 608px;
      p {
        font-size: 20px;
        text-align: center;
      }
    }
  }
  .copyright {
    position: fixed;
    bottom: 10px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    text-decoration:none;
  }
}
</style>
