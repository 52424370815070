<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.commit('common/setClientHeight')
  }
}
</script>

<style lang="scss">
@import './assets/css/common.css';
@import './assets/css/element.css';
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background: #f4f4f5;
  font-family: Poppins, Helvetica, Arial;
}
a{
  text-decoration:none
}
/* title h1 */

.main {
  min-height: 100%;
  max-height: 100%;
  > h1 {
    font-size: 20px;
    line-height: 50px;
  }
  .el-table {
    padding: 0 20px;
  }
}
.bg-white {
  background: white;
}
.list-filter {
  padding: 15px 8px 15px 20px;
  .input-with-select,
  .el-select {
    margin-right: 12px;
  }
}
</style>
